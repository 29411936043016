import React, { useMemo, useState } from "react";
import { useParams, useNavigate, Params } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckIcon from "@mui/icons-material/Check";
import { CircularProgress, Menu, MenuItem, Typography } from "@mui/material";
import { ErrorScreen } from "components/common/error -screen/error-screen.component";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import { PageSection, DetailWrapper, ActionBar } from "components/common/Wrapper";
import CustomTable from "components/table/table.component";
import { ButtonText } from "components/common/Buttons";
import { TableBodyCell } from "components/table/table.style";
import { useRequest } from "hooks/useRequest";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { convertIpAddress, getDateString, storeActiveSerial } from "utils/util-functions";
import { Spinner } from "components/common/spinner/spinner.component";
import useConfirm from "hooks/useConfirm";
import DetailForm from "./components/form-component/device-detail-form.component";
import { SectionHeader } from "./components/form-component/DeviceDetail.style";
import PENDING_GRID_COLUMNS, { ACTION_JSON, EI_GRID_COLUMNS, PendingGridColumnsTypes } from "./device-detail.config";
import { Popup } from "../../../components/common/popup/popup.component";
import { changeDeviceStatusFromUI, updateVpnDeviceStatus } from "../../../api/DeviceHelpers";
import { showWarning } from "../../../utils/notifications";
import { DeviceDetailDataTypes } from "../devices.config";

export const VPN_CONNECTED = "Connected";
export const VPN_CONNECTION_FAILED = "Connection Failed";
export const VPN_ERROR = "Error";

export function DeviceDetailPage(): JSX.Element {
  const [getConfirmation, Confirmation] = useConfirm();
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [isControlLaunching, setIsControlLaunching] = useState<boolean>(false);
  const [isVpnInitialized, setIsVpnInitialized] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const VPN_NOT_CONNECTED: string = "Not Connected";
  const { sn: serialNumber }: Readonly<Params<string>> = useParams();
  const navigate = useNavigate();
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [popupText, setPopupText] = useState<string>("");

  const {
    data: detailData,
    isLoading: isGettingDetail,
    mutate: mutateDetail,
    error: detailError,
  } = useRequest(`eiStatusUIHook?serialNumber=${serialNumber}`);

  // Initially load vpn data and check if 'vpnStatus' is 'Connected'.
  const { data: vpnData } = useRequest(`vpnUIHook?serialNumber=${serialNumber}`);

  const vpnConnectionOnClose = () => {
    setIsControlLaunching(false);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const errorPopupOnClose = () => {
    setIsError(false);
  };

  if (isControlLaunching && vpnData.vpnStatus === VPN_CONNECTED) {
    if (serialNumber) {
      sessionStorage.setItem("serialNumber", serialNumber);
    }

    navigate(`/launch-mission-control/${serialNumber}`);
    setIsControlLaunching(false);
    setIsVpnInitialized(false);
  }

  if (
    isControlLaunching &&
    isVpnInitialized &&
    (vpnData.vpnStatus === VPN_CONNECTION_FAILED || vpnData.vpnStatus === VPN_ERROR)
  ) {
    if (vpnData.vpnStatus === VPN_CONNECTION_FAILED) {
      showWarning("VPN connection could not be established. Please try later or contact support.");
    } else if (vpnData.vpnStatus === VPN_ERROR) {
      showWarning(
        "VPN connection could not be established. Please check for errors in the device system log or contact support.",
      );
    }
    setIsControlLaunching(false);
    setIsVpnInitialized(false);
  }

  const deviceDetail = useMemo(() => {
    if (detailData && detailData?.deviceData) {
      return {
        ...detailData.deviceData[0],
        lanIpAddress: convertIpAddress(detailData.deviceData[0].lanIpAddress || ""),
      };
    }
    return null;
  }, [detailData]);

  const {
    data: pendingActions,
    isLoading: isLoadingActions,
    mutate: mutatePendingActions,
    error: pendingActionError,
  } = useRequest(`pendingActionsUIHook?serialNumber=${serialNumber}`);

  const pendingActionsData = useMemo(
    () =>
      pendingActions?.map((item: DeviceDetailDataTypes) => ({
        ...item,
        completed: item.completed ? "Yes" : "No",
      })),
    [pendingActions],
  );

  const goToLocation = () => {
    storeActiveSerial(serialNumber);
    navigate(`/map/${serialNumber}`);
  };

  const errorMessage = useMemo(() => {
    let message = "";

    if (!isLoadingActions && pendingActionError) {
      const { error: pendingErrorText } = pendingActionError.data;
      message += `${pendingErrorText}\n`;
    }

    if (!isGettingDetail && detailError) {
      const { error: detailErrorText } = detailError.data;
      message += detailErrorText;
    }

    return message ? `Errors occurred while calling the API:\n${message}` : null;
  }, [isLoadingActions, pendingActionError, isGettingDetail, detailError]);

  if (errorMessage) {
    return <ErrorScreen text={errorMessage} />;
  }

  const open = Boolean(actionsAnchorEl);
  const handleActionsClick = (event: { currentTarget: HTMLElement }) => {
    setActionsAnchorEl(event.currentTarget);
  };

  // DETAIL ACTIONS
  const handleUUIDReset = async (): Promise<void> => {
    const isFactoryReset = await getConfirmation({
      title: "Reset Cloud UUID",
      content:
        "Warning: If your UUID is showing in Mission Control>Expert Mode>System>MegaFi Configuration, do not proceed. If you do not have a UUID in Mission Control>Expert Mode>System>MegaFi Configuration, proceeding may fix your MegaPortal connection. If you are having further difficulty syncing your device to MegaPortal, please contact support@nextivityinc.com",
      buttonTxt: ButtonText.Reset,
    });

    if (!isFactoryReset) {
      return;
    }

    setPopupText("Resetting Cloud UUID...");
    setIsLoading(true);

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      resetCloudUUID: true,
    });

    setIsLoading(false);
    mutatePendingActions?.();
  };

  const handleRefresh = async (): Promise<void> => {
    handleActionsClose();

    const isRefreshValues = await getConfirmation({
      title: "Refresh Values",
      content: "Are you sure to refresh values?",
      buttonTxt: ButtonText.Refresh,
    });
    if (!isRefreshValues) {
      return;
    }

    setPopupText("Refreshing values...");
    setIsLoading(true);

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      getDeviceDetails: true,
    });

    setIsLoading(false);
    mutatePendingActions?.();
  };

  const handleReboot = async (): Promise<void> => {
    handleActionsClose();

    const isRebootDevice = await getConfirmation({
      title: "Reboot Device",
      content: "Are you sure? You will lose connectivity while the device reboots.",
      buttonTxt: ButtonText.Reboot,
    });
    if (!isRebootDevice) {
      return;
    }

    setPopupText("Rebooting device...");
    setIsLoading(true);

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      rebootDevice: true,
    });

    setIsLoading(false);
    mutatePendingActions?.();
  };

  const handleLocationTrail = async (): Promise<void> => {
    handleActionsClose();

    const isConfirmed = await getConfirmation({
      title: "Location Tracking",
      content: `Are you sure you want to ${deviceDetail.locationTrailEnabled ? "disable" : "enable"} location tracking`,
      buttonTxt: `${deviceDetail.locationTrailEnabled ? ButtonText.Disable : ButtonText.Enable}`,
    });
    if (!isConfirmed) {
      return;
    }
    const data = deviceDetail.locationTrailEnabled
      ? { disableLocationTrail: true }
      : { enableLocationTrail: true, getDeviceDetails: true };

    setPopupText(`${deviceDetail.locationTrailEnabled ? "Disabling" : "Enabling"} location trail...`);
    setIsLoading(true);

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      ...data,
    });

    setIsLoading(false);
    mutatePendingActions?.();
    mutateDetail?.();
  };

  const launchMissionControl = async () => {
    // If respoonse is 'Not Connected' call 'VPN Initialize API'.

    handleActionsClose();

    try {
      setIsControlLaunching(true);

      if ((vpnData.vpnStatus === VPN_NOT_CONNECTED || vpnData.vpnStatus === VPN_ERROR) && !isVpnInitialized) {
        await updateVpnDeviceStatus(serialNumber);
        setIsVpnInitialized(true);
      }

      if (vpnData.vpnStatus === VPN_CONNECTED) {
        setIsControlLaunching(false);
        setIsVpnInitialized(false);

        if (serialNumber) {
          sessionStorage.setItem("serialNumber", serialNumber);
        }

        navigate(`/launch-mission-control/${serialNumber}`);
      }
    } catch (error: any) {
      console.warn("vpnerror", error);
      setIsControlLaunching(false);
      setIsVpnInitialized(false);
      setIsError(true);
      setErrorText(error.message);
    }
  };

  const getColumnValue = (value: string | number | boolean, columnId: string) => {
    const actionValues: { value: string | number | boolean } = { value };

    if (columnId === "isEnabled") {
      return <CheckIcon />;
    }

    // The pending action comes over as a long string. We break it up with the following
    if (columnId === ACTION_JSON && typeof actionValues.value === "string") {
      if (actionValues.value.includes("Date=")) {
        actionValues.value.replace("Date=", "");

        const formatedDate = getDateString(actionValues.value);

        return formatedDate;
      }

      const regex = /"[^"\\]*(?:\\[\s\S][^"\\]*)*"/g;
      const actionsArray = actionValues.value.match(regex);
      const actionIndex = actionsArray?.indexOf("ei.action");
      const pendingAction = actionIndex && actionsArray && actionsArray[actionIndex + 2].replaceAll('"', "");

      return pendingAction;
    }
    return value;
  };

  const getMissionControlMenuItem = () => {
    if (!deviceDetail?.allowVPNConnection || !deviceDetail?.online) {
      return <div />;
    }

    return <MenuItem onClick={launchMissionControl}>Launch Mission Control</MenuItem>;
  };

  const columns = useMemo(
    () => [
      {
        field: "type",
        label: "Type",
        type: "string",
        width: 150,
      },
      { field: "port", label: "Port", width: 150 },
      { field: "rxBytes", label: "Received Bytes", width: 150 },
      { field: "txBytes", label: "Transmitted Bytes", width: 150 },
      {
        field: "isEnabled",
        label: "Enabled",
        type: "boolean",
        width: 150,
      },
    ],
    [],
  );

  return (
    <DetailWrapper>
      <ActionBar style={{ marginBottom: 0 }}>
        <NxtIconButton onClick={() => goToLocation()} text="Show Location" icon={<LocationOnIcon />} />

        <NxtIconButton
          id="device-actions-button"
          aria-controls={open ? "device-actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          icon={<MoreVertIcon />}
          onClick={handleActionsClick}
          text="Options"
        />

        <Menu
          id="device-actions-menu"
          aria-labelledby="device-actions-button"
          anchorEl={actionsAnchorEl}
          open={open}
          onClose={handleActionsClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleRefresh} disabled={!deviceDetail?.online || !deviceDetail?.allowVPNConnection}>
            Refresh Values
          </MenuItem>
          <MenuItem onClick={handleReboot} disabled={!deviceDetail?.online}>
            Reboot Device
          </MenuItem>
          <MenuItem onClick={handleLocationTrail}>
            {" "}
            {deviceDetail?.locationTrailEnabled ? "Disable" : "Enable"} Location Tracking
          </MenuItem>
          {getMissionControlMenuItem()}
        </Menu>
        <Confirmation />
      </ActionBar>

      <PageSection>
        <DetailForm
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          setIsLoadingDetail={setIsLoadingDetail}
          isLoadingDetail={isLoadingDetail || isGettingDetail}
          mutateDetail={mutateDetail}
        />
      </PageSection>

      <PageSection>
        <SectionHeader>
          <span className="header-title">EI Interfaces</span>
        </SectionHeader>

        <CustomTable>
          <CustomTable.Header columns={columns} />

          <CustomTable.Body isLoading={isGettingDetail} deviceData={deviceDetail?.eiInterfaces}>
            {deviceDetail?.eiInterfaces.map((row: any, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${row.eiInterfaces}${i}`}>
                {EI_GRID_COLUMNS.map((column: PendingGridColumnsTypes) => {
                  const value = getColumnValue(row[column.id as keyof DeviceDetailDataTypes], column.id);

                  return (
                    <TableBodyCell key={column.id} align={column.align} style={{ color: "#656D78" }}>
                      {column.format && typeof value === "number" ? column.format(value) : value}
                    </TableBodyCell>
                  );
                })}
              </TableRow>
            ))}
          </CustomTable.Body>
        </CustomTable>
      </PageSection>

      <PageSection>
        <SectionHeader>
          <span className="header-title">Pending Updates</span>
        </SectionHeader>

        <CustomTable>
          <CustomTable.Header columns={PENDING_GRID_COLUMNS} />

          <CustomTable.Body isLoading={isLoadingActions} deviceData={pendingActionsData}>
            {pendingActionsData?.map((row: DeviceDetailDataTypes, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${row.eiSerialNumber}${i}`}>
                {PENDING_GRID_COLUMNS.map((column: PendingGridColumnsTypes) => {
                  const value = getColumnValue(row[column.id as keyof DeviceDetailDataTypes], column.id);

                  return (
                    <TableBodyCell key={column.id} align={column.align} style={{ color: "#656D78" }}>
                      {column.format && typeof value === "number" ? column.format(value) : value}
                    </TableBodyCell>
                  );
                })}
              </TableRow>
            ))}
          </CustomTable.Body>
        </CustomTable>
      </PageSection>

      <Popup
        title="Establishing VPN connection...Please wait."
        open={isControlLaunching}
        primaryBtn={{ onClick: vpnConnectionOnClose, text: "Cancel" }}
      >
        <Typography>Note: This process may take up to a minute or longer.</Typography>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "32px" }}>
          <CircularProgress size={60} variant="indeterminate" />
        </div>
      </Popup>

      {isError && (
        <Popup title="Error" open={isError} primaryBtn={{ onClick: errorPopupOnClose, text: "Cancel" }}>
          <Typography>{errorText}</Typography>
        </Popup>
      )}

      <Popup title={popupText} open={isLoading}>
        <Spinner />
      </Popup>
    </DetailWrapper>
  );
}
