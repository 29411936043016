import axiosInst from "api/config/axiosConfig";
import { inventoryResponseData, inventoryStatistics } from "./inventory.depot";

export interface InventoryStatusTypes {
  serialNumber: string;
  uploadStatus: string;
}

export interface InventoryValueTypes {
  deviceType: string;
  serialNumber: string;
  imei: string;
  mac: string;
}

export const DEVICE_SPEEDWAY: string = "Speedway";

export async function postInventoryData(data: InventoryValueTypes) {
  try {
    const inventoryArray: InventoryStatusTypes[] = [];
    const response = axiosInst.post("/beta/inventoryUIHook", data);
    const responseData = (await response).data;
    const inventoryDepot = inventoryResponseData.data;

    inventoryArray.push(...inventoryDepot);
    inventoryArray.push(responseData);

    inventoryStatistics.setData({ resultsQty: inventoryArray.length });
    inventoryResponseData.setData(inventoryArray);
  } catch (error: any) {
    console.error("error", error);
  }
}
