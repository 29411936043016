import React from "react";

import { DepotBin } from "depot/depot-bin";

interface InventoryTypes {
  serialNumber: string;
  uploadStatus: string;
}

export const inventoryResponseData = new DepotBin<InventoryTypes[]>([]);

export const inventoryStatistics = new DepotBin<any>({
  resultsQty: 0,
  resultsProcessed: 0,
});
