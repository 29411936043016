import React, { useEffect, useState } from "react";
import { PageTitle } from "components/common/Text";
import { useCSVReader } from "react-papaparse";
import ErrorIcon from "@mui/icons-material/Error";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { TableHeaderCell } from "components/table/table.style";
import { CommonText } from "components/common/text/text.component";
import { PrimaryButton, ButtonText } from "components/common/Buttons";
import { DEVICE_SPEEDWAY, InventoryStatusTypes, InventoryValueTypes, postInventoryData } from "./inventory.helpers";
import style from "./inventory.module.css";
import { inventoryResponseData } from "./inventory.depot";

interface ResultQtyTypes {
  successQty: number;
  existsQty: number;
}

const InventoryPage = (): JSX.Element => {
  const { CSVReader } = useCSVReader();

  // below goes on table
  const [inventoryStatus, setInventoryStatus] = useState<InventoryStatusTypes[]>([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(false);
  const [devicesProcessed, setDevicesProcessed] = useState<number>(0);
  const [resultsQty, setResultsQty] = useState<ResultQtyTypes>({
    successQty: 0,
    existsQty: 0,
  });

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const inventoryDepotData = inventoryResponseData.observable.subscribe({
      next: (data: InventoryStatusTypes[]) => {
        setInventoryStatus(data);
      },
    });
    return () => inventoryDepotData.unsubscribe();
  }, []);

  useEffect(() => {
    let successCount = 0;
    let existsCount = 0;

    inventoryStatus.forEach((value: InventoryStatusTypes) => {
      if (value.uploadStatus === "SerialNumber added") {
        successCount++;
      }
      if (value.uploadStatus === "Rejected, SerialNumber already exists") {
        existsCount++;
      }

      setResultsQty({ successQty: successCount, existsQty: existsCount });
    });
  }, [inventoryStatus]);

  const postInventory = async (inventory: InventoryValueTypes[]): Promise<void> => {
    inventory.forEach(async (data: InventoryValueTypes) => {
      setIsUploadDisabled(true);
      await postInventoryData(data);
      setIsUploadDisabled(false);
    });
  };

  const getResultStats = (acceptedFile: any) => {
    if (!acceptedFile) return;

    return (
      <div>
        <div>
          <CommonText>
            Results: <br />
            {acceptedFile.name} was successfully uploaded! <br />
          </CommonText>
          {resultsQty.successQty ? (
            <CommonText>
              {resultsQty.successQty} of {devicesProcessed} devices were created
            </CommonText>
          ) : (
            <CommonText>
              {resultsQty.existsQty} of the {devicesProcessed} devices already exist in the database
            </CommonText>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <CSVReader
        onUploadAccepted={(results: any) => {
          const newArr: InventoryValueTypes[] = [];
          results.data.forEach(async (data: string[], i: number, array: string[][]) => {
            if (i < 1) {
              return;
            }

            const filteredList = array.filter((device: string[], index: number) => device.length > 1 && index > 0);

            setDevicesProcessed(filteredList.length);

            const inventoryData: InventoryValueTypes = {
              deviceType: DEVICE_SPEEDWAY,
              serialNumber: "",
              imei: "",
              mac: "",
            };

            const [deviceSn, deviceImei, deviceMacAddr] = data;

            if (!deviceSn) {
              return;
            }
            const convertedSn: string = Number(deviceSn).toString();

            inventoryData.serialNumber = convertedSn;
            inventoryData.imei = deviceImei;
            inventoryData.mac = deviceMacAddr;

            newArr.push(inventoryData);
          });

          postInventory(newArr);

          setIsUploadDisabled(false);
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar }: any) => (
          <>
            <PageTitle>Inventory</PageTitle>

            <div className={style.importAreaContainer}>
              <div className={style.searchButtonMargin}>
                <PrimaryButton disabled={isUploadDisabled} {...getRootProps()}>
                  {ButtonText.CsvUpload}
                </PrimaryButton>
              </div>
              {getResultStats(acceptedFile)}
            </div>

            <ProgressBar class={style.progressBarColor} />
          </>
        )}
      </CSVReader>

      <TableContainer className={style.tableContainer} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={style.tableHeaderCell} />
              <TableHeaderCell className={style.tableHeaderCell}>Serial Number</TableHeaderCell>
              <TableHeaderCell className={style.tableHeaderCell}>Upload Status</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryStatus
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: InventoryStatusTypes) => (
                <TableRow key={row.serialNumber} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell className={style.tableHeaderCell} align="left">
                    {row.uploadStatus !== "Rejected, SerialNumber already exists" ? (
                      <DownloadDoneIcon color="success" />
                    ) : (
                      <ErrorIcon color="warning" />
                    )}
                  </TableCell>
                  <TableCell className={style.tableHeaderCell} component="th" scope="row">
                    {row.serialNumber}
                  </TableCell>
                  <TableCell className={style.tableHeaderCell} component="th" scope="row">
                    {row.uploadStatus}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className={style.paginationContainer}>
          <TablePagination
            component="div"
            count={inventoryStatus.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
    </>
  );
};
export default InventoryPage;
